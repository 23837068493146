import React from 'react';
// import React, { useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import csv from 'csv-parser';
// import fileReaderStream from 'filereader-stream';
import { Card } from 'react-bootstrap';
// import { Form, Row, Col, Card, Table } from 'react-bootstrap';
// import WordCountList from '../components/WordCountList/WordCountList';
// import LoaderButton from '../components/LoaderButton/LoaderButton';
// import config from '../config';
import './Upload.css';
// import { useAppContext } from '../libs/contextLib';
// import { checkDMYFormat, twoDigit } from '../libs/utilLib';
// import {
//     batchUpdateWordCount,
//     updateLocalWordCount,
// } from '../libs/wordCountLib';
// import { updateProfile } from '../libs/profileLib';
// import { updateTotals } from '../libs/totalLib';

const Upload = () => {
    return (
        <>
            <Card className="explain">
                <Card.Body>
                    <Card.Title>Temporarily unavailable</Card.Title>
                    <Card.Text>
                        Apologies, but the upload of a CSV file is currently
                        unavailable due to a software issue.
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
};
/* v.1.2.5 - Remove UPLOAD due to issues with CSV
            https://github.com/mafintosh/csv-parser/issues/183
*/
// const Upload = () => {
//     const { profile, setProfile } = useAppContext();
//     const { totals, setTotals } = useAppContext();
//     const { wordCounts, setWordCounts } = useAppContext();
//     const { setJustAdded } = useAppContext();

//     const navigate = useNavigate();

//     const file = useRef(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [isUploaded, setIsUploaded] = useState(false);
//     const [newWords, setNewWords] = useState([]);

//     const massageWords = (wordcounts) => {
//         const dmy = checkDMYFormat(wordcounts);
//         const tempWordCounts = [];
//         for (var i = 0; i < wordcounts.length; i++) {
//             var day, month, year;
//             if (dmy) {
//                 [day, month, year] = wordcounts[i].wordcountDate
//                     .split('/')
//                     .map((x) => parseInt(x));
//             } else {
//                 [month, day, year] = wordcounts[i].wordcountDate
//                     .split('/')
//                     .map((x) => parseInt(x));
//             }

//             // We choose not to count negatives
//             if (wordcounts[i].words < 0) {
//                 wordcounts[i].words = 0;
//             }

//             var foundIt = false;

//             // Look for an existing date (and search from the end,
//             // it's likely the user is nearer to now than the start)
//             for (var j = tempWordCounts.length - 1; j >= 0; j--) {
//                 // If we find it...
//                 if (
//                     `${year}-${month}-${day}` ===
//                     tempWordCounts[j].wordcountDate.substr(0, 10)
//                 ) {
//                     // We just add it straight on
//                     tempWordCounts[j].words =
//                         tempWordCounts[j].words + wordcounts[i].words;

//                     foundIt = true;
//                     break;
//                 }
//             }

//             // If we didn't...
//             if (!foundIt) {
//                 // Add to the end of the list
//                 const entry = {
//                     wordcountDate: `${year}-${twoDigit(month)}-${twoDigit(
//                         day
//                     )}`,
//                     words: wordcounts[i].words,
//                 };
//                 tempWordCounts.push(entry);
//             }
//         }
//         return tempWordCounts;
//     };

//     const handleFileChange = (event) => {
//         if (event?.target?.files?.[0]) {
//             file.current = event.target.files[0];
//         }
//     };

//     const handleSubmit = (event) => {
//         event.preventDefault();

//         // if (file.current) {
//         if (file?.current?.size > config.MAX_ATTACHMENT_SIZE) {
//             alert(
//                 `File must be smaller than ${
//                     config.MAX_ATTACHMENT_SIZE / 1000000
//                 } MB.`
//             );
//             return;
//         }

//         setIsLoading(true);

//         var uploadedWords = [];

//         fileReaderStream(file.current)
//             .pipe(csv(['wordcountDate', 'words']))
//             .on('data', (data) => {
//                 if (parseInt(data.words)) {
//                     uploadedWords.push({
//                         wordcountDate: data.wordcountDate,
//                         words: parseInt(data.words),
//                     });
//                 }
//             })
//             .on('end', () => {
//                 setNewWords(massageWords(uploadedWords));
//                 setIsUploaded(true);
//                 setIsLoading(false);
//             });
//         // }
//     };

//     const handleConfirmationSubmit = async (event) => {
//         event.preventDefault();

//         setIsLoading(true);
//         const oldTotals = { ...totals };

//         if (newWords.length) {
//             // Update locally - merges the new values with the old
//             const [
//                 tempTotals,
//                 tempProfile,
//                 tempWordCounts,
//                 tempNewWords,
//             ] = updateLocalWordCount(totals, profile, wordCounts, newWords);

//             // Make a difference object to send to the totals API
//             var totalDiff = {};
//             for (var p in tempTotals) {
//                 if (oldTotals.hasOwnProperty(p)) {
//                     if (oldTotals[p] !== tempTotals[p]) {
//                         totalDiff[p] = tempTotals[p] - oldTotals[p];
//                     }
//                 } else {
//                     totalDiff[p] = tempTotals[p];
//                 }
//             }

//             setJustAdded(totalDiff.words);

//             // And send the updates to the API.

//             await Promise.all([
//                 // newWords includes the old words, so we can just push straight into DB
//                 batchUpdateWordCount(newWords),
//                 // The user is the only one who'd update their own profile,
//                 // so we can send the whole thing to the API & overwrite
//                 updateProfile(profile),
//                 // We have to send a diff to the API for the totals,
//                 // As there might be more than one user making changes at once
//                 updateTotals(totalDiff),
//             ]);

//             // Now make our temp variables permanent, will refresh the dashboard
//             setWordCounts(tempWordCounts);
//             setTotals({ ...tempTotals });
//             setProfile({ ...tempProfile });
//             setNewWords(tempNewWords);
//         }

//         setIsLoading(false);

//         navigate('/');
//     };

//     const renderForm = () => {
//         return (
//             <>
//                 <Card className="explain">
//                     <Card.Body>
//                         <Card.Title>
//                             Upload a CSV file with historical word counts
//                         </Card.Title>
//                         <Card.Text>
//                             This form allows you to upload a CSV file containing
//                             a list of word counts that you wish to add for each
//                             day. Each line should contain a date (in either{' '}
//                             <em>dd/mm/yyyy</em> or <em>mm/dd/yyyy</em> format)
//                             in the first column, and a number representing the
//                             word count for that day in the second column. You
//                             can have a header row if you like, but it is not
//                             required.
//                         </Card.Text>
//                         <Card.Text>
//                             <strong>Note:</strong> Any word counts uploaded
//                             through this form will be <strong>added to</strong>{' '}
//                             any existing count for that day.
//                         </Card.Text>
//                     </Card.Body>
//                 </Card>

//                 <Row className="mt-3">
//                     <Col sm={12} md={6}>
//                         <Form onSubmit={handleSubmit}>
//                             <Form.Group controlId="file">
//                                 <Form.Label>Upload a CSV file:</Form.Label>
//                                 <Form.Control
//                                     onChange={handleFileChange}
//                                     type="file"
//                                 />
//                             </Form.Group>

//                             <LoaderButton
//                                 block
//                                 type="submit"
//                                 size="lg"
//                                 variant="primary"
//                                 isLoading={isLoading}
//                             >
//                                 Upload
//                             </LoaderButton>
//                         </Form>
//                     </Col>
//                 </Row>
//                 <div className="mt-3 explanation">
//                     <p>
//                         To export a suitable CSV file from{' '}
//                         <strong>Scrivener</strong>, follow these instructions:
//                     </p>
//                     <ol>
//                         <li>
//                             Within Scrivener, with the project you want open, go
//                             to the <strong>Project</strong> menu.
//                         </li>
//                         <li>
//                             Select the menu option{' '}
//                             <strong>Writing History</strong>
//                         </li>
//                         <li>
//                             Press the button marked <strong>Export</strong> at
//                             the bottom left of the window
//                         </li>
//                         <li>
//                             In the new window that opens, ensure the pull down
//                             menu in the centre says <strong>Export days</strong>{' '}
//                             and not months
//                         </li>
//                         <li>
//                             Ensure the only check box ticked is{' '}
//                             <strong>Words(Total)</strong>
//                         </li>
//                         <li>
//                             Give the file a name and press <strong>Save</strong>
//                         </li>
//                         <li>
//                             Press <strong>Choose File</strong> on this web page,
//                             select that file and press <strong>Upload</strong>
//                         </li>
//                     </ol>
//                     <p>
//                         If you do not use Scrivener, then any{' '}
//                         <em>comma separated variable</em> file will do, but
//                         ensure that the first column contains the date of the
//                         entry, and the second contains the number of words.
//                     </p>
//                     <p>For example:</p>
//                     <Col sm={12} md={6}>
//                         <Table bordered size="sm" className="csv">
//                             <thead>
//                                 <tr>
//                                     <th>Date</th>
//                                     <th>Words</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>22/03/2020</td>
//                                     <td>247</td>
//                                 </tr>
//                                 <tr>
//                                     <td>10/06/2020</td>
//                                     <td>240</td>
//                                 </tr>
//                             </tbody>
//                         </Table>
//                     </Col>
//                 </div>
//             </>
//         );
//     };

//     const renderConfirmationForm = () => {
//         return (
//             <>
//                 <Row>
//                     <Col sm={12} md={6}>
//                         <Form onSubmit={handleConfirmationSubmit}>
//                             <Form.Group controlId="confirmUpload" size="lg">
//                                 <Form.Label>
//                                     Check the list below and press{' '}
//                                     <strong>Confirm</strong> if you are happy
//                                 </Form.Label>
//                                 <LoaderButton
//                                     block
//                                     size="lg"
//                                     type="submit"
//                                     variant="success"
//                                     isLoading={isLoading}
//                                 >
//                                     Confirm
//                                 </LoaderButton>
//                             </Form.Group>
//                         </Form>
//                     </Col>
//                 </Row>

//                 <WordCountList wordcounts={newWords} />
//             </>
//         );
//     };

//     return (
//         <div className="Upload">
//             {isUploaded === false ? renderForm() : renderConfirmationForm()}
//         </div>
//     );
// };
export default Upload;
