import { useState, useRef } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { dayNames, monthNames } from '../../libs/utilLib';
import { formatDate, twoDigit } from '../../libs/utilLib';
import AddModal from '../AddModal/AddModal';

import {
    getPrevMonth,
    getNextMonth,
    makeCalendar,
    getStartDate,
    getEndDate,
} from '../../libs/calendarLib';
import { useAppContext } from '../../libs/contextLib';
import './MonthCalendar.css';

const MonthCalendar = () => {
    const { wordCounts } = useAppContext();
    const { profile } = useAppContext();
    const { isLoading } = useAppContext();

    const modalRef = useRef(null);

    const today = new Date();

    const [renderMonth, setRenderMonth] = useState(today.getMonth());
    const [renderYear, setRenderYear] = useState(today.getFullYear());

    // For the modal dialog box
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // If we ask for the previous month
    const backOneMonth = (event) => {
        // Don't redraw the page or follow the link
        event.preventDefault();

        // Work out what the previous month is
        const [prevYear, prevMonth] = getPrevMonth(renderYear, renderMonth);
        // And change our view
        setRenderYear(prevYear);
        setRenderMonth(prevMonth);
    };

    // If we ask for the next month
    const forwardOneMonth = (event) => {
        // Don't redraw the page or follow the link
        event.preventDefault();

        // Work out what the next month is
        const [nextYear, nextMonth] = getNextMonth(renderYear, renderMonth);

        // There's a chance it's in the future, so we won't go there if it is
        if (nextYear !== 0) {
            // Otherwise we change our view
            setRenderYear(nextYear);
            setRenderMonth(nextMonth);
        }
    };

    const goToToday = (event) => {
        event.preventDefault();
        setRenderMonth(today.getMonth());
        setRenderYear(today.getFullYear());
    };

    const handleDateClick = (event, year, month, day) => {
        event.preventDefault();
        const currentDate = `${year}-${twoDigit(month + 1)}-${twoDigit(day)}`;

        modalRef.current.changeDate(currentDate);
        // modalRef.current.showAlert(currentDate);

        handleShow();
    };

    // Take this month, and ask for a calendar object
    const monthCalendar = makeCalendar(
        getStartDate(renderYear, renderMonth),
        getEndDate(renderYear, renderMonth),
        wordCounts,
        profile.target
    );

    // Create an empty array to populate
    let monthCal = [];

    // Push the first entry down to account for the day of the week
    // Take first entry's name of the day and look it up in the list of days
    const offset = dayNames.findIndex(
        (element) => element === monthCalendar[0].dayName
    );

    // Put in that many 'dummy' entries that won't show up
    for (let i = 0; i < offset; i++) {
        monthCal.push(<div key={'skip' + i} className="skip"></div>);
    }

    // Now iterate over every day and push those entries onto the list
    for (let j = 0; j < monthCalendar.length; j++) {
        if (monthCalendar[j].extras === 'notyet') {
            monthCal.push(
                <div className="daynumsquare notyet" key={j}>
                    <p className="fullday">
                        <span className="dayname">
                            {monthCalendar[j].dayName}
                        </span>
                        <span className="date">
                            {monthNames[renderMonth]} {monthCalendar[j].day}
                        </span>
                    </p>
                    <p className="daynum">{monthCalendar[j].day}</p>
                    <div className="wordcount button notyet">
                        {monthCalendar[j].wordcount}
                    </div>
                </div>
            );
        } else {
            // Create a tool tip - date and words when you hover
            const dateTip = `${formatDate(
                monthCalendar[j].date,
                'short'
            )} - ${monthCalendar[j].wordcount.toLocaleString()} words`;

            monthCal.push(
                <OverlayTrigger
                    key={j}
                    placement="top"
                    overlay={<Tooltip id={dateTip}>{dateTip}</Tooltip>}
                >
                    {({ ref, ...triggerHandler }) => (
                        <div
                            ref={ref}
                            {...triggerHandler}
                            className={
                                'daynumsquare ' + monthCalendar[j].extras
                            }
                            onClick={(e) =>
                                handleDateClick(
                                    e,
                                    renderYear,
                                    renderMonth,
                                    j + 1
                                )
                            }
                            id={`${renderYear}/${renderMonth}/${j + 1}`}
                            key={j}
                        >
                            <p className="fullday">
                                <span className="dayname">
                                    {monthCalendar[j].dayName}
                                </span>
                                <span className="date">
                                    {monthNames[renderMonth]}{' '}
                                    {monthCalendar[j].day}
                                </span>
                            </p>
                            <p className="daynum">{monthCalendar[j].day}</p>
                            <div
                                id={`${renderYear}/${renderMonth}/${j + 1}/${
                                    monthCalendar[j].wordcount
                                }`}
                                className={
                                    'wordcount button ' +
                                    monthCalendar[j].extras
                                }
                            >
                                {monthCalendar[j].wordcount}
                            </div>
                        </div>
                    )}
                </OverlayTrigger>
            );
        }
    }

    return (
        <>
            <AddModal handleClose={handleClose} show={show} ref={modalRef} />
            <Card className="calendar monthCalendar mt-3">
                <Card.Header>
                    <span className="arrow" id="prev" onClick={backOneMonth}>
                        <FaArrowAltCircleLeft />
                    </span>
                    <div onClick={goToToday} className="monthName">
                        {monthNames[renderMonth]} {renderYear}
                    </div>
                    <span className="arrow" id="next" onClick={forwardOneMonth}>
                        <FaArrowAltCircleRight />
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className="calwrap">
                        <div
                            id="monthCal"
                            className={`monthCal ${isLoading ? 'loading' : ''}`}
                        >
                            <div className="header daynameblock" id="dayofweek">
                                <div className="daynamesquare weekend">
                                    <p className="dayname weekend">Sun</p>
                                </div>
                                <div className="daynamesquare">
                                    <p className="dayname">Mon</p>
                                </div>
                                <div className="daynamesquare">
                                    <p className="dayname">Tue</p>
                                </div>
                                <div className="daynamesquare">
                                    <p className="dayname">Wed</p>
                                </div>
                                <div className="daynamesquare">
                                    <p className="dayname">Thu</p>
                                </div>
                                <div className="daynamesquare">
                                    <p className="dayname">Fri</p>
                                </div>
                                <div className="daynamesquare weekend">
                                    <p className="dayname weekend">Sat</p>
                                </div>
                            </div>
                            <div id="daygrid">{monthCal}</div>
                        </div>

                        <div className="monthCal calendarMeta">
                            <div className="key">Key:</div>
                            <span className="keyEntry">
                                <div className="square notdone"></div>
                                <div className="caption">No words entered</div>
                            </span>
                            <span className="keyEntry">
                                <div className="square fail"></div>
                                <div className="caption">
                                    Target not reached
                                </div>
                            </span>
                            <span className="keyEntry">
                                <div className="square pass"></div>
                                <div className="caption">Target passed</div>
                            </span>
                            <span className="keyEntry">
                                <div className="square today"></div>
                                <div className="caption">Today</div>
                            </span>
                            <span className="keyEntry">
                                <div className="square notyet"></div>
                                <div className="caption">Future date</div>
                            </span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
export default MonthCalendar;
