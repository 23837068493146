import { Card } from 'react-bootstrap';
import { useAppContext } from '../../libs/contextLib';

interface wordList {
    title: string;
    author: string;
    words: number;
}

const Comparison = (): JSX.Element => {
    const { profile } = useAppContext();
    const { isLoading } = useAppContext();

    // In case we're not longer than anything on the list, let's have this
    var longest = {
        title: 'The Very Hungry Caterpillar',
        author: 'Eric Carle',
        words: 224,
    };

    var cardTitle = (
        <>
            Please log some more words, and we'll compare your output to famous
            works of literature
        </>
    );
    var cardText = <>&nbsp;</>;

    // We need a few words already to make it worthwhile
    if (profile.totalWords > 224) {
        // This is our list of comparison words per novel
        const compareWords: wordList[] = require('../../misc/compareWords.json');

        // We could ensure that the source file is always sorted, but this isn't too bad...
        const sortedCompareWords = compareWords
            .slice()
            .sort((a, b) => a.words - b.words);

        for (var i = 0; i < sortedCompareWords.length; i++) {
            if (profile.totalWords > sortedCompareWords[i].words) {
                longest = { ...sortedCompareWords[i] };
            }
        }
        cardTitle = (
            <>
                You have written and logged
                <strong> {profile.totalWords.toLocaleString()} </strong> words.
            </>
        );
        cardText = (
            <>
                That's more than <em>{longest.title}</em> by {longest.author} (
                {longest.words.toLocaleString()} words)
            </>
        );
    }

    return (
        <Card className={`mt-3 ${isLoading ? 'loading' : ''}`}>
            <Card.Body>
                <Card.Title>{cardTitle}</Card.Title>
                <Card.Text>
                    <small>{cardText}</small>
                </Card.Text>
                <></>
            </Card.Body>
        </Card>
    );
};

export default Comparison;
