import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppContext } from './libs/contextLib';

import AuthenticatedRoute from './components/Routes/AuthenticatedRoute';
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute';

import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Graphs from './pages/Graphs.jsx';
import Upload from './pages/Upload';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import Profile from './pages/Profile.jsx';
import Info from './pages/Info';
import NotFound from './pages/NotFound';

const MyRoutes = () => {
    const { isAuthenticated } = useAppContext();
    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    isAuthenticated ? (
                        <Navigate replace to="/dashboard" />
                    ) : (
                        <Home />
                    )
                }
            />
            {/* There must be a better way to do this! */}
            <Route
                exact
                path="/dashboard"
                element={
                    <AuthenticatedRoute>
                        <Dashboard />
                    </AuthenticatedRoute>
                }
            />
            <Route
                exact
                path="/graphs"
                element={
                    <AuthenticatedRoute>
                        <Graphs />
                    </AuthenticatedRoute>
                }
            />
            <Route
                exact
                path="/upload"
                element={
                    <AuthenticatedRoute>
                        <Upload />
                    </AuthenticatedRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <UnauthenticatedRoute>
                        <Login />
                    </UnauthenticatedRoute>
                }
            />
            <Route
                exact
                path="/signup"
                element={
                    <UnauthenticatedRoute>
                        <Signup />
                    </UnauthenticatedRoute>
                }
            />
            <Route
                exact
                path="/login/reset"
                element={
                    <UnauthenticatedRoute>
                        <ResetPassword />
                    </UnauthenticatedRoute>
                }
            />
            <Route exact path="/info" element={<Info />} />
            <Route
                exact
                path="/profile"
                element={
                    <AuthenticatedRoute>
                        <Profile />
                    </AuthenticatedRoute>
                }
            />
            {/* Finally, catch all unmatched routes */}
            <Route>
                <Route element={<NotFound />} />
            </Route>
        </Routes>
    );
};
export default MyRoutes;
