import { Carousel, Image, Card } from 'react-bootstrap';
import imgPen from '../../images/carousel/pen.jpg';
import imgLaptop from '../../images/carousel/laptop.jpg';
import imgTypewriter from '../../images/carousel/typewriter.jpg';

import './FPCarousel.css';

const FPCarousel = (): JSX.Element => {
    const items = [
        {
            key: '1',
            src: imgPen,
            altText: 'Fountain pen writing',
            header: 'Track your writing progress',
            caption: 'Want to see how your writing habits change over time?',
            content:
                'Writer Log gives you a simple way to keep track of your writing progress. Daily, weekly and beyond.',
        },
        {
            key: '2',
            src: imgLaptop,
            header: 'Set goals and exceed them',
            altText: 'Woman writing on a laptop',
            caption: 'Want to challenge yourself to write more?',
            content:
                'Writer log lets you set your own goals, and work at your own pace.',
        },
        {
            key: '3',
            src: imgTypewriter,
            altText: 'Classic typewriter',
            header: 'Get insights into your writing habits',
            caption: 'Want to know when you write best?',
            content:
                "Learn when you're at your most productive, and when you fall behind.",
        },
    ];
    return (
        <>
            <Carousel className="mt-3">
                {items.map(
                    ({ key, src, header, altText, caption, content }) => {
                        return (
                            <Carousel.Item key={key}>
                                <Image
                                    rounded
                                    fluid
                                    className="d-block w-75"
                                    src={src}
                                    alt={altText}
                                />
                                <Carousel.Caption>
                                    <h2>{header}</h2>
                                    <p>{caption}</p>
                                    <p className="small">{content}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        );
                    }
                )}
            </Carousel>
            <div className="fp-carousel">
                {items.map(({ key, header, caption, content }) => {
                    return (
                        <Card key={key}>
                            <Card.Body>
                                <Card.Title as="h1">{header}</Card.Title>
                                <Card.Text>
                                    <small>{caption}</small>
                                </Card.Text>
                            </Card.Body>
                            {content ? (
                                <Card.Footer>
                                    <small>{content}</small>
                                </Card.Footer>
                            ) : (
                                <></>
                            )}
                        </Card>
                    );
                })}
            </div>
        </>
    );
};

export default FPCarousel;
