import { useContext, createContext } from 'react';

const appCtxDefaultValue: AppContextInterface = {
    wordCounts: [],
    setWordCounts: () => {},
    profile: {
        target: 0,
        totalWords: 0,
        email: '',
        displayName: '',
        firstName: '',
        lastName: '',
    },
    setProfile: () => {},
    totals: {},
    setTotals: () => {},
    justAdded: false,
    setJustAdded: () => {},
    isLoading: false,
    setIsLoading: () => {},
};

const AppContext = createContext(appCtxDefaultValue);

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppContext, useAppContext };
