import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import LoaderButton from '../LoaderButton/LoaderButton';
import { onError } from '../../libs/errorLib';
import { updateWordCount, updateLocalWordCount } from '../../libs/wordCountLib';
import { useAppContext } from '../../libs/contextLib';
import { twoDigit } from '../../libs/utilLib';

const AddModal = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        changeDate(date) {
            setWcDate(date);
        },
    }));

    const show = props.show;
    const handleClose = props.handleClose;
    // Word counts come from the context
    const { wordCounts, setWordCounts } = useAppContext();
    const { profile, setProfile } = useAppContext();
    const { totals, setTotals } = useAppContext();
    const { setJustAdded } = useAppContext();

    // ref((d) => setWcDate(d));

    var currentDate;

    // Default to today when we open
    const today = new Date();
    // But we need a particular format
    currentDate = `${today.getFullYear()}-${twoDigit(
        today.getMonth() + 1
    )}-${twoDigit(today.getDate())}`;

    // Here's where we'll track the various values
    const [dailyCount, setDailyCount] = useState(0);
    const [wcDate, setWcDate] = useState(currentDate);
    const [isLoading, setIsLoading] = useState(false);

    // Can't submit zero...
    const validateForm = () => {
        return dailyCount > 0;
    };

    // Whenever we change the date, load up the real wordcount
    useEffect(() => {
        const onLoad = () => {
            try {
                // We changed date, so we might not have an entry
                setDailyCount(0);
                // If we do, we'll find it (and search from the end,
                // it's likely the user is nearer to now than the start)
                for (var i = wordCounts.length - 1; i >= 0; i--) {
                    if (
                        new Date(wcDate).getTime() ===
                        wordCounts[i].wordcountDate.getTime()
                    ) {
                        setDailyCount(wordCounts[i].words);
                        break;
                    }
                }
            } catch (e) {
                onError(e);
            }
            setIsLoading(false);
        };

        onLoad();
    }, [wcDate, wordCounts]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            // Push to API - this will also update total counts, days, etc
            await updateWordCount(wcDate, dailyCount);
            // Change our local values, if needed
            const [
                tempTotals,
                tempProfile,
                tempWordCounts,
            ] = updateLocalWordCount(totals, profile, wordCounts, [
                {
                    wordcountDate: wcDate,
                    words: dailyCount,
                },
            ]);
            // Now make our temp variables permanent
            setWordCounts(tempWordCounts);
            setTotals({ ...tempTotals });
            setProfile({ ...tempProfile });

            // And close the window, reset the button
            setIsLoading(false);
            handleClose();
            setJustAdded(dailyCount);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add words</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="addWordCountForm" onSubmit={handleSubmit}>
                    <Form.Group controlId="wcDate">
                        <Form.Control
                            value={wcDate}
                            max={currentDate}
                            type="date"
                            onChange={(e) => setWcDate(e.target.value)}
                        />
                    </Form.Group>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="wordCount">
                                <Form.Control
                                    autoFocus
                                    value={dailyCount > 0 ? dailyCount : ''}
                                    type="number"
                                    onChange={(e) =>
                                        setDailyCount(parseInt(e.target.value))
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                            <LoaderButton
                                block
                                type="submit"
                                size="md"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                            >
                                Add
                            </LoaderButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
});

export default AddModal;
