import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isLocal = process.env.NODE_ENV === 'development';

const initSentry = () => {
    if (isLocal) {
        console.log('Sentry tracing disabled in dev');
        return;
    }
    Sentry.init({
        release: process.env.REACT_APP_NAME + process.env.REACT_APP_VERSION,
        dsn:
            'https://f027038fefdb43e6a5afb9000cf50e4b@o510535.ingest.sentry.io/5609470',
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
};

const logError = (error, errorInfo = null) => {
    if (isLocal) {
        return;
    }

    Sentry.withScope((scope) => {
        errorInfo && scope.setExtras(errorInfo);
        Sentry.captureException(error);
    });
};

const onError = (error) => {
    let errorInfo = {};
    let message = error.toString();

    if (!(error instanceof Error) && error.message) {
        // Auth errors
        errorInfo = error;
        message = error.message;
        error = new Error(message);
    } else if (error.config && error.config.url) {
        // API errors
        errorInfo.url = error.config.url;
    }
    logError(error, errorInfo);

    alert(message);
};

export { initSentry, logError, onError };
