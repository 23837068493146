import { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Image, Alert, InputGroup } from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import LoaderButton from '../components/LoaderButton/LoaderButton';
import { s3Upload } from '../libs/awsLib';
import { useAppContext } from '../libs/contextLib';
import { updateProfile } from '../libs/profileLib';
import { onError } from '../libs/errorLib';
import { formatDate, timeout } from '../libs/utilLib';
import config from '../config';
import './Profile.css';
import { GiQuillInk } from 'react-icons/gi';

const Profile = () => {
    const file = useRef(null);
    const profileImageURL = useRef();
    const { profile, setProfile } = useAppContext();
    const { isLoading, setIsLoading } = useAppContext();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    // On first load, get the profile
    useEffect(() => {
        let mounted = true;
        const onLoad = async () => {
            if (profile.image) {
                profileImageURL.current = await Storage.vault.get(
                    profile.image
                );
            }
        };

        if (mounted) {
            onLoad();
        }
        return () => (mounted = false);
    }, [profile]);

    const validateForm = () => {
        let valid = 0;
        if (!isLoading) {
            if (
                profile.firstName.length > 0 &&
                profile.lastName.length > 0 &&
                profile.displayName.length > 0 &&
                profile.target > 0
            ) {
                valid = 1;
            }
        }
        return valid;
    };

    const handleFileChange = (event) => {
        file.current = event.target.files[0];
    };

    const handleChange = (event) => {
        setProfile({
            ...profile,
            [event.target.id]: event.target.value,
        });
    };

    const notifySuccess = async () => {
        setShowSuccess(true);
        await timeout(5000);
        setShowSuccess(false);
    };

    const notifyError = async () => {
        setShowFailure(true);
        await timeout(5000);
        setShowFailure(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `File must be smaller than ${
                    config.MAX_ATTACHMENT_SIZE / 1000000
                } MB.`
            );
            return;
        }
        setIsLoading(true);

        // Remove URLs from Twitter & FB to make things easier.
        if (profile.twitter) {
            if (
                profile.twitter.startsWith('https://') ||
                profile.twitter.startsWith('twitter.com')
            ) {
                profile.twitter = profile.twitter.split('/').pop();
            }
        }
        if (profile.facebook) {
            if (
                profile.facebook.startsWith('https://') ||
                profile.facebook.startsWith('facebook.com')
            ) {
                profile.facebook = profile.facebook.split('/').pop();
            }
        }

        try {
            const image = file.current ? await s3Upload(file.current) : null;
            await updateProfile({ ...profile, image });
            setProfile({ ...profile, image });
            notifySuccess();
        } catch (e) {
            onError(e);
            notifyError();
        }
        setIsLoading(false);
    };

    return (
        <div className="profile">
            {showSuccess && (
                <Alert variant="success">
                    Your profile changes have been saved!
                </Alert>
            )}
            {showFailure && (
                <Alert variant="danger">
                    Sorry, something went wrong. Please try again later.
                </Alert>
            )}

            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col className="profileImage" sm={12} md={3}>
                        {profile.image ? (
                            <Image
                                rounded
                                className="border"
                                alt="profile"
                                src={profileImageURL.current}
                            />
                        ) : (
                            <GiQuillInk className="border rounded" size={250} />
                        )}
                        <div className="form-label createdOn">
                            <span>Member since:</span>
                            <span>
                                {formatDate(new Date(profile.createdAt)) || ''}
                            </span>
                        </div>
                    </Col>
                    <Col sm={12} md={9}>
                        <Form.Group controlId="firstName">
                            <Form.Label>Given Name:</Form.Label>
                            <Form.Control
                                value={profile.firstName || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>Family Name:</Form.Label>
                            <Form.Control
                                value={profile.lastName || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="displayName">
                            <Form.Label>What should we call you:</Form.Label>
                            <Form.Control
                                value={profile.displayName || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email address:</Form.Label>
                            <Form.Control
                                value={profile.email || ''}
                                onChange={handleChange}
                                readOnly
                            />
                        </Form.Group>
                        <Row>
                            <Col sm={12} md={4}>
                                <Form.Group controlId="target">
                                    <Form.Label>
                                        Pick a daily word count target:
                                    </Form.Label>
                                    <Form.Control
                                        value={profile.target || 200}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={8}>
                                <Form.Group controlId="website">
                                    <Form.Label>Website:</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            https://
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={profile.website || ''}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={4}>
                                <Form.Group controlId="twitter">
                                    <Form.Label>Twitter:</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>@</InputGroup.Text>
                                        <Form.Control
                                            value={profile.twitter || ''}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={8}>
                                <Form.Group controlId="goodreads">
                                    <Form.Label>
                                        Goodreads author page:
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            https://www.goodreads.com/author/show/
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={profile.goodreads || ''}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Form.Group controlId="bio">
                    <Form.Label>Tell us about yourself:</Form.Label>
                    <Form.Control
                        value={profile.bio || ''}
                        as="textarea"
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="file">
                    <Form.Label>Change profile picture:</Form.Label>
                    <Form.Control onChange={handleFileChange} type="file" />
                </Form.Group>

                <LoaderButton
                    type="submit"
                    size="lg"
                    variant="primary"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Update
                </LoaderButton>
            </Form>
        </div>
    );
};
export default Profile;
