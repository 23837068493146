import { Button } from 'react-bootstrap';
import { BsArrowRepeat } from 'react-icons/bs';
import './LoaderButton.css';

const LoaderButton = ({
    isLoading,
    className = '',
    disabled = false,
    ...props
}) => {
    return (
        <Button
            disabled={disabled || isLoading}
            className={`mt-3 mb-3 LoaderButton ${className}`}
            {...props}
        >
            {isLoading && <BsArrowRepeat className="spinning" />}
            {!isLoading && props.children}
        </Button>
    );
};
export default LoaderButton;
