import { API } from 'aws-amplify';

const fetchProfile = (id) => {
    const profile = API.get('writerlog', `/api/users/${id}`);
    return profile;
};

const updateProfile = (profile) => {
    return API.put('writerlog', `/api/users`, {
        body: profile,
    });
};

const createProfile = (profile) => {
    return API.post('writerlog', `/api/users`, {
        body: {
            displayName: profile.nickname,
            firstName: profile.given_name,
            lastName: profile.family_name,
            email: profile.email,
            // image: 'placeholder',
            // bio: 'placeholder',
            // website: 'placeholder',
            // twitter: 'placeholder',
            // facebook: 'placeholder',
        },
    });
};

export { fetchProfile, updateProfile, createProfile };
