import { Card } from 'react-bootstrap';
import { useAppContext } from '../../libs/contextLib';
import { formatDate } from '../../libs/utilLib';
import LineGraph from '../Graphs/LineGraph';
import BarGraph from '../Graphs/BarGraph';

import './UserStats.css';

const UserStats = () => {
    const { profile } = useAppContext();
    const { isLoading } = useAppContext();

    let cards = [];

    if (profile.totalWords) {
        cards.push({
            key: 'total',
            title: `${profile.totalWords.toLocaleString()} words`,
            body: 'since you joined',
            footer: `${formatDate(new Date(profile.createdAt))}`,
        });
    } else {
        cards.push({
            key: 'welcome',
            title: 'Welcome!',
            body: 'Add some words to the system with the pen icon at bottom right to see your stats',
            footer: '\u00a0',
        });
    }

    cards.push({
        key: 'days',
        title: `${profile.totalDays?.toLocaleString() ?? 0} days`,
        body: 'of writing logged',
        footer: `${
            profile.totalDays
                ? Math.round(
                      profile.totalWords / profile.totalDays
                  ).toLocaleString()
                : 0
        } words per day average`,
    });

    // The logic is too interlocking to do simply
    let printCurrent = false;
    let printLongest = false;

    // If we have streaks at all...
    if (profile.longestStreak && profile.currentStreak) {
        // And they're over 0 days
        if (profile.longestStreak.length > 0) {
            // If the longest streak is longer than the current one, print it
            if (profile.longestStreak.length > profile.currentStreak.length) {
                printLongest = true;
            }

            // If the latest streak finished yesterday (or today)
            if (
                Date.parse(profile.currentStreak.endDate) +
                    48 * 60 * 60 * 1000 >
                Date.now()
            ) {
                // We're on the latest streak, so print it
                printCurrent = true;
            } else {
                // Whatever the longest one is gets printed
                printLongest = true;
            }
        }
    }
    if (printLongest || isLoading) {
        cards.push({
            key: 'longest',
            title: profile.longestStreak
                ? `${profile.longestStreak.length.toLocaleString()} days`
                : '0 days',
            body: 'in your longest streak',
            footer: profile.longestStreak
                ? `${formatDate(
                      profile.longestStreak.startDate
                  )} to ${formatDate(profile.longestStreak.endDate)}`
                : '\u00a0',
        });
    }
    if (printCurrent) {
        cards.push({
            key: 'current',
            title: profile.currentStreak
                ? `${profile.currentStreak.length.toLocaleString()} days`
                : '0 days',
            body: 'in your current streak',
            footer: profile.currentStreak
                ? `${formatDate(
                      profile.currentStreak.startDate
                  )} to ${formatDate(profile.currentStreak.endDate)}`
                : '\u00a0',
        });
    }

    return (
        <>
            <div className="mt-3 stats d-flex flex-wrap justify-content-md-between justify-content-center">
                {cards.map(({ key, title, body, footer }) => (
                    <Card
                        key={key}
                        className={`m-1 ${isLoading ? 'loading' : ''}`}
                    >
                        <Card.Body>
                            <Card.Title as="h1">{title}</Card.Title>
                            <Card.Text>
                                <small>{body}</small>
                            </Card.Text>
                        </Card.Body>
                        {footer ? (
                            <Card.Footer>
                                <small>{footer}</small>
                            </Card.Footer>
                        ) : (
                            <></>
                        )}
                    </Card>
                ))}{' '}
            </div>
            <div className="mt-3 graphs d-flex flex-wrap justify-content-between">
                <LineGraph thumbnail={true} />
                <BarGraph thumbnail={true} />
            </div>
        </>
    );
};
export default UserStats;
