import { Component } from 'react';
import { Card } from 'react-bootstrap';
import { logError } from '../../libs/errorLib';

export default class ErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logError(error, errorInfo);
    }

    render() {
        return this.state.hasError ? (
            <Card className="ErrorBoundary mt-3">
                <Card.Body>
                    <Card.Title as="h1">Error</Card.Title>
                    <Card.Text>
                        Sorry there was a problem loading this page
                    </Card.Text>
                </Card.Body>
            </Card>
        ) : (
            this.props.children
        );
    }
}
