import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAppContext } from '../libs/contextLib';
import { timeout } from '../libs/utilLib';

import MonthCalendar from 'src/components/Calendars/MonthCalendar';
import YearCalendar from '../components/Calendars/YearCalendar';
import Lander from '../components/Lander/Lander';
import UserStats from '../components/UserStats/UserStats';
import Comparison from '../components/Comparison/Comparison';
import Totals from '../components/Totals/Totals';
import Share from '../components/Share/Share';
import AddButton from '../components/AddButton/AddButton';
import AddModal from '../components/AddModal/AddModal';
import SupportButton from '../components/SupportButton/SupportButton';
import './Dashboard.css';

const Dashboard = () => {
    const { isAuthenticated } = useAppContext();
    const { justAdded, setJustAdded } = useAppContext();
    const { hasData } = useAppContext();
    const [showShare, setShowShare] = useState(false);

    // For the modal dialog box
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        const onLoad = async () => {
            if (!isAuthenticated || !hasData) {
                return;
            }
            if (justAdded) {
                showShareDialog();
            }
        };

        onLoad();
        // showShareDialog is a func, I don't think we want to rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, justAdded, hasData]);

    const showShareDialog = async () => {
        setShowShare(true);
        await timeout(10000);
        setShowShare(false);
        setJustAdded(0);
    };

    const renderData = () => {
        return (
            <>
                <AddButton handleShow={handleShow} />
                <AddModal handleClose={handleClose} show={show} />
                <SupportButton />
                <Row>
                    <Col sm={12} md={8}>
                        <Totals />
                        <Comparison />
                    </Col>
                    <Col sm={12} md={4}>
                        <MonthCalendar />
                    </Col>
                </Row>
                <YearCalendar />
                <UserStats />
                {showShare && <Share />}
            </>
        );
    };

    return (
        <div className="Dashboard">
            {isAuthenticated ? renderData() : <Lander />}
        </div>
    );
};

export default Dashboard;
