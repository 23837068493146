import { Alert } from 'react-bootstrap';
import './Banner.css';

const Banner = (): JSX.Element => {
    let linkText = 'please report any problems to ';
    if (Math.random() < 0.2) {
        linkText = 'please email suggestions to ';
    }
    return (
        <Alert variant="warning" className="text-center banner">
            <span className="bannerText">
                This site is still under development -{' '}
            </span>
            <Alert.Link href="mailto:bugs@writerlog.app">
                <span className="linkText">{linkText}</span>
                <span className="email">bugs@writerlog.app</span>
            </Alert.Link>
        </Alert>
    );
};

export default Banner;
