import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
    VictoryChart,
    VictoryAxis,
    VictoryTheme,
    VictoryLine,
    VictoryLabel,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryScatter,
    Flyout,
} from 'victory';
import { useAppContext } from '../../libs/contextLib';
import { formatDate, inFillCounts } from '../../libs/utilLib';

import './LineGraph.css';

const LineGraph = ({ thumbnail = false, className = '' }) => {
    const { wordCounts } = useAppContext();
    const { profile } = useAppContext();
    const { hasData } = useAppContext();

    // Milliseconds per day
    const msPerDay = 24 * 60 * 60 * 1000;
    // Defaults for while we're loading
    var startDate = Date.now() - msPerDay;
    var endDate = Date.now();

    const runningTotal = [];
    const runningTarget = [];
    var totalLine = <></>;
    var totalScatter = <></>;
    var targetLine = <></>;
    var targetScatter = <></>;
    var ticks = 1;

    if (!hasData || wordCounts.length === 0) {
        return <></>;
    } else {
        if (wordCounts.length < 3) {
            return (
                <Card className="m-1">
                    <Card.Header>Cumulative Totals</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <small>
                                Add more data to get a graph of your progress
                            </small>
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        } else {
            // Sort by date
            var sortedCounts = inFillCounts(wordCounts);

            // Let's not have too many marks on the graph
            ticks =
                sortedCounts.length - 1 > 0 && sortedCounts.length < 20
                    ? sortedCounts.length - 1
                    : 20;

            // Offset the X-axis to the first data, not the start of time
            startDate = sortedCounts[0].wordcountDate.getTime();
            // endDate = sortedCounts[
            //     sortedCounts.length - 1
            // ].wordcountDate.getTime();

            let total = 0;

            for (var item in sortedCounts) {
                total = total + sortedCounts[item].words;
                runningTotal.push({
                    wordcountDate: sortedCounts[item].wordcountDate.getTime(),
                    words: total,
                });
            }
            const days = (endDate - startDate) / msPerDay;
            for (var i = 0; i < days; i++) {
                var currentDate = startDate + i * msPerDay;
                runningTarget.push({
                    wordcountDate: currentDate,
                    words: profile.target * (i + 1),
                });
            }

            totalScatter = (
                <VictoryScatter
                    data={runningTotal}
                    x="wordcountDate"
                    y="words"
                    style={{ data: { fill: 'black' } }}
                    size={2}
                ></VictoryScatter>
            );
            totalLine = (
                <VictoryLine
                    data={runningTotal}
                    x="wordcountDate"
                    y="words"
                    interpolation="catmullRom"
                    // sortkey="wordCountDate"
                    style={{
                        data: {
                            stroke: 'black',
                            strokeWidth: 1,
                        },
                    }}
                    labels={({ datum }) =>
                        `${formatDate(
                            new Date(datum.wordcountDate),
                            'short'
                        )}: ${datum.words.toLocaleString()}`
                    }
                    labelComponent={
                        thumbnail ? (
                            <></>
                        ) : (
                            <VictoryTooltip
                                style={{
                                    fontSize: 4,
                                }}
                                flyoutPadding={1}
                                cornerRadius={1}
                                flyoutStyle={{
                                    strokeWidth: 0,
                                }}
                                pointerLength={0}
                                flyoutComponent={<Flyout />}
                            />
                        )
                    }
                />
            );
            targetScatter = (
                <VictoryScatter
                    data={runningTarget}
                    x="wordcountDate"
                    y="words"
                    style={{ data: { fill: 'blue' } }}
                    size={2}
                ></VictoryScatter>
            );

            targetLine = (
                <VictoryLine
                    data={runningTarget}
                    x="wordcountDate"
                    y="words"
                    interpolation="catmullRom"
                    style={{
                        data: {
                            stroke: 'blue',
                            strokeWidth: 1,
                        },
                    }}
                />
            );

            var xAxis = (
                <VictoryAxis
                    tickCount={ticks}
                    tickLabelComponent={<></>}
                    gridComponent={<></>}
                />
            );
            var yAxis = (
                <VictoryAxis
                    dependentAxis
                    tickLabelComponent={<></>}
                    gridComponent={<></>}
                />
            );

            if (!thumbnail) {
                xAxis = (
                    <VictoryAxis
                        fixLabelOverlap={true}
                        gridComponent={<></>}
                        tickCount={ticks}
                        tickFormat={(x) =>
                            `${formatDate(new Date(x), 'short')}`
                        }
                        tickLabelComponent={
                            <VictoryLabel
                                angle={60}
                                style={{ fontSize: 4 }}
                                dx={-6}
                                dy={-5}
                                textAnchor="start"
                            />
                        }
                    />
                );
                yAxis = (
                    <VictoryAxis
                        dependentAxis
                        gridComponent={<></>}
                        crossAxis={false}
                        // tickFormat={(x) => `${x / 1000}k`}
                        tickLabelComponent={
                            <VictoryLabel
                                style={{ fontSize: 4 }}
                                textAnchor="start"
                            />
                        }
                    />
                );
            }

            var graphCard = (
                <Card className={`m-1 lineGraph graph ${className}`}>
                    <Card.Header>Cumulative Totals</Card.Header>
                    <Card.Body>
                        <VictoryChart
                            height={200}
                            scale={{ x: 'time', y: 'linear' }}
                            minDomain={{ x: startDate }}
                            maxDomain={{ x: endDate }}
                            padding={
                                thumbnail
                                    ? 0
                                    : {
                                          bottom: 30,
                                          left: 20,
                                          top: 10,
                                          right: 10,
                                      }
                            }
                            containerComponent={<VictoryVoronoiContainer />}
                            theme={VictoryTheme.material}
                        >
                            {xAxis}
                            {yAxis}
                            {totalLine}
                            {totalScatter}
                            {targetLine}
                            {targetScatter}
                        </VictoryChart>
                    </Card.Body>
                    {!thumbnail && (
                        <Card.Footer>
                            <div className="lineGraphMeta graphMeta">
                                <div className="key">Key:</div>
                                <div className="line words"></div>
                                <div className="caption">
                                    Cumulative Wordcount
                                </div>
                                <div className="line target"></div>
                                <div className="caption">Target wordcount</div>
                            </div>
                        </Card.Footer>
                    )}
                </Card>
            );
            if (thumbnail) {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="line-graph-tooltip">
                                    Click to expand
                                </Tooltip>
                            }
                        >
                            {({ ref, ...triggerHandler }) => (
                                <LinkContainer to="/graphs">
                                    {graphCard}
                                </LinkContainer>
                            )}
                        </OverlayTrigger>
                    </>
                );
            } else {
                return <>{graphCard}</>;
            }
        }
    }
};
export default LineGraph;
