import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { SiCoffeescript } from 'react-icons/si';
import './SupportButton.css';

const SupportButton = (): JSX.Element => {
    return (
        <>
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip id="support-button-tooltip">
                        Buy Me A Coffee
                    </Tooltip>
                }
            >
                {({ ref, ...triggerHandler }) => (
                    <Button
                        ref={ref}
                        {...triggerHandler}
                        className="float-left"
                        aria-label="Buy me a coffee"
                    >
                        <a
                            aria-label="Buy me a coffee"
                            href="http://buymeacoffee.com/markhood"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <SiCoffeescript className="spin" size={28} />
                        </a>
                    </Button>
                )}
            </OverlayTrigger>
        </>
    );
};
export default SupportButton;
