import { API } from 'aws-amplify';
import { splitDate, sortCounts } from './utilLib';

const sumWordCounts = (wordcounts) => {
    let wordcount = 0;
    wordcounts.forEach((item) => {
        wordcount += parseInt(item.words);
    });
    return wordcount;
};

const fetchWordCount = (date) => {
    const [year, month, day] = splitDate(date);
    const words = API.get(
        'writerlog',
        `/api/wordcounts/${year}/${month}/${day}`
    );
    return words.words;
};

const fetchWordCountArray = async (date) => {
    let words = {};
    if (date) {
        const [year, month, day] = splitDate(date);
        if (day) {
            words = await API.get(
                'writerlog',
                `/api/wordcountslist/${year}/${month}/${day}`
            );
        } else if (month) {
            words = await API.get(
                'writerlog',
                `/api/wordcountslist/${year}/${month}`
            );
        } else if (year) {
            words = await API.get('writerlog', `/api/wordcountslist/${year}`);
        }
    } else {
        words = await API.get('writerlog', `/api/wordcountslist`);
    }
    // @@@FIXME@@@ Need to return in the correct format!
    // When this is not needed, I can also take out the await and async,
    //  return a promise
    for (let i = 0; i < words.words.length; i++) {
        words.words[i].wordcountDate = new Date(words.words[i].wordcountDate);
    }

    return words.words;
};

const updateWordCount = (date, count) => {
    const [year, month, day] = splitDate(date);
    return API.put('writerlog', `/api/wordcounts/${year}/${month}/${day}`, {
        body: { words: count },
    });
};

const batchUpdateWordCount = (newWords) => {
    return API.put('writerlog', `/api/wordcounts/batch`, {
        body: newWords,
    });
};

const updateLocalWordCount = (totals, profile, wordCounts, newWords) => {
    // Iterate over every new entry we've been asked to add
    for (var i = 0; i < newWords.length; i++) {
        // The object to add if we need to
        const newWordCount = {
            ID: profile.ID,
            wordcountDate: new Date(newWords[i].wordcountDate),
            words: newWords[i].words,
        };
        var foundIt = false;
        // How much are we adding to the totals
        let wordDelta = parseInt(newWords[i].words);

        // Look for an existing date (and search from the end,
        // it's likely the user is nearer to now than the start)
        for (var j = wordCounts.length - 1; j >= 0; j--) {
            // If we find it...
            if (
                new Date(newWords[i].wordcountDate).getTime() ===
                wordCounts[j].wordcountDate.getTime()
            ) {
                const oldWords = parseInt(wordCounts[j].words);
                // Replace the entry
                wordCounts[j].words = wordDelta;

                // Now the delta needs to change too
                wordDelta = wordDelta - oldWords;

                // And we want to send the new value to the API
                newWords[i].words = wordCounts[j].words;

                // No need to change the streaks
                // No need to change the user's logged days
                // No need to change the total's logged days

                // And we can stop looking
                foundIt = true;
                break;
            }
        }
        // If we didn't...
        if (!foundIt) {
            // Add to the end of the list
            wordCounts.push(newWordCount);

            // Add to the user's logged days
            profile.totalDays = profile.totalDays + 1;

            // Add to the total's logged days
            totals.days = totals.days + 1;
        }

        // Couple of handy variables
        const yearString = newWords[i].wordcountDate.substr(0, 4);
        const monthString = yearString + newWords[i].wordcountDate.substr(5, 2);
        const dayString = monthString + newWords[i].wordcountDate.substr(8, 2);

        // Update the total words, year, month and day
        totals.words = wordDelta + (totals.words ? totals.words : 0);
        totals[dayString] =
            wordDelta + (totals[dayString] ? totals[dayString] : 0);
        totals[monthString] =
            wordDelta + (totals[monthString] ? totals[monthString] : 0);
        totals[yearString] =
            wordDelta + (totals[yearString] ? totals[yearString] : 0);

        // Update the user's words, year and month
        profile.totalWords =
            wordDelta + (profile.totalWords ? profile.totalWords : 0);
        profile['total' + monthString] =
            wordDelta +
            (profile['total' + monthString]
                ? profile['total' + monthString]
                : 0);
        profile['total' + yearString] =
            wordDelta +
            (profile['total' + yearString] ? profile['total' + yearString] : 0);
    }

    // Add to the streaks
    // Calculate the streaks
    // Set some initial values
    let longest = {
        length: 0,
        startDate: 0,
        endDate: 0,
    };
    let latest = {
        length: 0,
        startDate: 0,
        endDate: 0,
    };
    let current = {
        length: 0,
        startDate: 0,
        endDate: 0,
    };

    let lastDate = new Date(1970, 0, 1);
    // Sort by date
    const sortedCounts = sortCounts(wordCounts);

    // Step over every date we got
    for (var k = 0; k < sortedCounts.length; k++) {
        // If we're one day on from the last date we looked at
        if (
            Date.parse(sortedCounts[k].wordcountDate) ===
            Date.parse(lastDate) + 24 * 60 * 60 * 1000
        ) {
            // Increment the latest streak
            latest.length += 1;
            latest.endDate = sortedCounts[k].wordcountDate;

            // If the latest streak is the longest yet
            if (latest.length > longest.length) {
                // Update the longest to be the same as the latest
                longest = Object.assign({}, latest);
            }
            // If we're more than one day out
        } else {
            // Reset the counters
            latest.length = 1;
            latest.startDate = sortedCounts[k].wordcountDate;
            latest.endDate = sortedCounts[k].wordcountDate;
        }
        // Either way, change the lastDate to be the last date we looked at
        lastDate = sortedCounts[k].wordcountDate;
    }

    // If the latest streak finished yesterday (or today)
    if (Date.parse(lastDate) + 48 * 60 * 60 * 1000 > Date.now()) {
        // We're on the latest streak
        current = latest;
    }
    profile.currentStreak = { ...current };
    profile.longestStreak = { ...longest };

    return [totals, profile, wordCounts, newWords];
};

export {
    sumWordCounts,
    fetchWordCount,
    fetchWordCountArray,
    updateWordCount,
    batchUpdateWordCount,
    updateLocalWordCount,
};
