import { Link } from 'react-router-dom';
import FPCarousel from '../components/Carousel/FPCarousel';
import './Home.css';

const Home = () => {
    return (
        <div className="Home">
            <div className="lander">
                <FPCarousel />
                <div className="mt-5">
                    <h1>Writer Log</h1>
                    <p>Track your writing progress day by day</p>
                </div>
                <div className="pt-3 pb-5">
                    <Link to="/login" className="btn btn-info btn-lg mr-3">
                        Login
                    </Link>
                    <Link to="/signup" className="btn btn-success btn-lg">
                        Signup
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Home;
