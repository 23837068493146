import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../libs/contextLib';

const AuthenticatedRoute = ({ children, ...rest }) => {
    const { pathname, search } = useLocation();
    const { isAuthenticated } = useAppContext();

    if (isAuthenticated) {
        return children;
    } else {
        return <Navigate replace to={`/login?redirect=${pathname}${search}`} />;
    }
};
export default AuthenticatedRoute;
