import { Card } from 'react-bootstrap';
import { roundNumber } from '../../libs/utilLib';
import { useAppContext } from '../../libs/contextLib';

const Totals = () => {
    const { totals } = useAppContext();
    const { isLoading } = useAppContext();
    const { profile } = useAppContext();

    return (
        <Card className={`totals ${isLoading ? 'loading' : ''} mt-3`}>
            <Card.Body>
                <Card.Title>Welcome back, {profile.displayName}!</Card.Title>
                <Card.Text>
                    Thanks for being one of our{' '}
                    {totals.users > 10 ? totals.users : ''} users who've logged{' '}
                    {roundNumber(totals.words)} words.
                </Card.Text>
            </Card.Body>
        </Card>
    );
};
export default Totals;
