import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton/LoaderButton';
import { useFormFields } from '../libs/hooksLib';
import { onError } from '../libs/errorLib';
import './ResetPassword.css';

const ResetPassword = () => {
    const [fields, handleFieldChange] = useFormFields({
        code: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    const validateCodeForm = () => {
        return fields.email.length > 0;
    };

    const validateResetForm = () => {
        return (
            fields.code.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    };

    const handleSendCodeClick = async (event) => {
        event.preventDefault();
        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.email);
            setCodeSent(true);
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    };

    const handleConfirmClick = async (event) => {
        event.preventDefault();
        setIsConfirming(true);

        try {
            await Auth.forgotPasswordSubmit(
                fields.email,
                fields.code,
                fields.password
            );
            setConfirmed(true);
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    };

    const renderRequestCodeForm = () => {
        return (
            <Form onSubmit={handleSendCodeClick}>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    type="submit"
                    isLoading={isSendingCode}
                    disabled={!validateCodeForm()}
                >
                    Send Confirmation
                </LoaderButton>
            </Form>
        );
    };

    const renderConfirmationForm = () => {
        return (
            <Form onSubmit={handleConfirmClick}>
                <Form.Group size="lg" controlId="code">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        value={fields.code}
                        onChange={handleFieldChange}
                    />
                    <Form.Text>
                        Please check your email ({fields.email}) for the
                        confirmation code.
                    </Form.Text>
                </Form.Group>
                <hr />
                <Form.Group size="lg" controlId="password">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    type="submit"
                    isLoading={isConfirming}
                    disabled={!validateResetForm()}
                >
                    Confirm
                </LoaderButton>
            </Form>
        );
    };

    const renderSuccessMessage = () => {
        return (
            <div className="success">
                <p>Your password has been reset.</p>
                <p>
                    <Link to="/login">
                        Click here to login with your new credentials.
                    </Link>
                </p>
            </div>
        );
    };

    return (
        <div className="ResetPassword">
            {!codeSent
                ? renderRequestCodeForm()
                : !confirmed
                ? renderConfirmationForm()
                : renderSuccessMessage()}
        </div>
    );
};
export default ResetPassword;
