import { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import LoaderButton from '../components/LoaderButton/LoaderButton';
import { useAppContext } from '../libs/contextLib';
import { useFormFields } from '../libs/hooksLib';
import { onError } from '../libs/errorLib';
import { createProfile } from '../libs/profileLib';
import './Signup.css';

const Signup = () => {
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
        given_name: '',
        family_name: '',
        nickname: '',
        confirmPassword: '',
        confirmationCode: '',
    });
    const navigate = useNavigate();
    const [newUser, setNewUser] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showPWAdvice, setPWShowAdvice] = useState(false);
    const [showBreach, setShowBreach] = useState(false);
    const { userHasAuthenticated } = useAppContext();
    const { setProfile } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    const validateForm = () => {
        return (
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.given_name.length > 0 &&
            fields.family_name.length > 0 &&
            fields.nickname.length > 0 &&
            fields.password === fields.confirmPassword
        );
    };

    const validateConfirmationForm = () => {
        return fields.confirmationCode.length > 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        setShowBreach(false);
        setShowError(false);
        setPWShowAdvice(false);

        // Sign the user up
        try {
            // If it works...
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password,
                attributes: {
                    given_name: fields.given_name,
                    family_name: fields.family_name,
                    nickname: fields.nickname,
                },
            });
            // Move on to the code section
            setIsLoading(false);
            setNewUser(newUser);
        } catch (e) {
            // If it fails...
            // And it's because the user exists...
            if (e.code === 'UsernameExistsException') {
                // See if we can resend the confirmation code
                try {
                    // If that works, we can move on to checking the new code
                    await Auth.resendSignUp(fields.email);
                    setNewUser(true);
                } catch (error) {
                    // Turn on the notification that they already exist
                    setShowError(true);
                }
            } else if (e.code === 'InvalidPasswordException') {
                if (
                    e.message ===
                    'Provided password cannot be used for security reasons.'
                ) {
                    setShowBreach(true);
                } else {
                    setPWShowAdvice(true);
                }
            } else {
                // Here is if it fails for some other reason than already existing
                onError(e);
            }
            setIsLoading(false);
        }
    };

    const handleConfirmationSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);
            userHasAuthenticated(true);

            const newProfile = await createProfile(fields);
            setProfile(newProfile);
            navigate('/');
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    };

    const renderConfirmationForm = () => {
        return (
            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId="confirmationCode" size="lg">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <Form.Text>Please check your email for the code.</Form.Text>
                </Form.Group>
                <LoaderButton
                    block
                    size="lg"
                    type="submit"
                    variant="success"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    Verify
                </LoaderButton>
            </Form>
        );
    };

    const renderForm = () => {
        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email" size="lg">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                {showError && (
                    <Alert variant="danger">
                        A user with that email address already exists. Have you{' '}
                        <Link to="/login/reset">forgotten your password?</Link>
                    </Alert>
                )}

                <Form.Group controlId="password" size="lg">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                {showBreach && (
                    <Alert variant="danger">
                        <p>
                            That password has been seen in a data breach. Please
                            use another one.
                        </p>
                        <a
                            href="https://haveibeenpwned.com/Passwords"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Click here for more information
                        </a>
                    </Alert>
                )}
                {showPWAdvice && (
                    <Alert variant="warning">
                        Password must be at least 8 characters, including upper
                        &amp; lower case, a symbol and a digit.
                    </Alert>
                )}

                <Form.Group controlId="confirmPassword" size="lg">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="given_name" size="lg">
                    <Form.Label>Your given name</Form.Label>
                    <Form.Control
                        type="text"
                        value={fields.given_name}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="family_name" size="lg">
                    <Form.Label>Your family name</Form.Label>
                    <Form.Control
                        type="text"
                        value={fields.family_name}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="nickname" size="lg">
                    <Form.Label>What should we call you?</Form.Label>
                    <Form.Control
                        type="text"
                        value={fields.nickname}
                        onChange={handleFieldChange}
                    />
                </Form.Group>

                <LoaderButton
                    block
                    size="lg"
                    type="submit"
                    variant="success"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Signup
                </LoaderButton>
            </Form>
        );
    };

    return (
        <div className="Signup">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    );
};
export default Signup;
