import { Button } from 'react-bootstrap';
import { RiPenNibFill } from 'react-icons/ri';
import './AddButton.css';

const AddButton = ({ handleShow }) => {
    return (
        <Button
            className="float"
            aria-label="Add a new wordcount"
            onClick={handleShow}
        >
            <RiPenNibFill className="spin" size={28} />
        </Button>
    );
};

export default AddButton;
