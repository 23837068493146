// Return a number as two digits, adding a leading 0 if required
// Always returns a string for consistency
const twoDigit = (number) => {
    return parseInt(number) < 10 ? '0' + parseInt(number) : number.toString();
};

// Make a list of dates from first log to last, fill in the dates we logged
// If we don't specify an end date in the call, assume it is today
const inFillCounts = (wordcounts, endDate = Date.now()) => {
    // Just an object with the bare data we need
    const minCounts = {};
    for (var x = 0; x < wordcounts.length; x++) {
        minCounts[wordcounts[x].wordcountDate] = wordcounts[x].words;
    }

    // Sort the list of word counts we do have
    const sortedCounts = sortCounts(wordcounts);

    // Milliseconds per day
    const msPerDay = 24 * 60 * 60 * 1000;
    // const todayMS = Date.now();

    // const endDate = todayMS;
    const startDate = sortedCounts[0].wordcountDate.getTime();

    const days = (endDate - startDate) / msPerDay;

    const tempCounts = [];
    for (var i = 0; i < days; i++) {
        const currentDate = new Date(startDate + msPerDay * i);
        const todayString = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );
        if (minCounts[currentDate]) {
            tempCounts.push({
                wordcountDate: todayString,
                words: minCounts[currentDate],
            });
        } else {
            tempCounts.push({
                wordcountDate: todayString,
                words: 0,
            });
        }
    }

    return tempCounts;
};

// Sort by date
const sortCounts = (wordCounts) => {
    return wordCounts
        .slice()
        .sort((a, b) => a.wordcountDate.getTime() - b.wordcountDate.getTime());
};

// Split a date from a picker into year, month, day
const splitDate = (date) => {
    // Can probably assume the format is correct
    // since it comes from a picker not a manual entry

    const [year, month, day] = date.split('-');
    return [parseInt(year), parseInt(month), parseInt(day)];
};

const ordinal = (number) => {
    // Special cases
    switch (number.toString()) {
        case '11':
        case '12':
        case '13':
            return `${number}th`;
        default:
            break;
    }
    switch (number.toString()[number.toString().length - 1]) {
        case '1':
            return `${number}st`;
        case '2':
            return `${number}nd`;
        case '3':
            return `${number}rd`;
        default:
            return `${number}th`;
    }
};

const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const getCurrentDate = () => {
    // Default to today
    const today = new Date();
    // But we need a particular format (and yes, adding 1900 is Y2K safe!)
    const currentDate = `${1900 + today.getYear()}-${twoDigit(
        today.getMonth() + 1
    )}-${twoDigit(today.getDate())}`;

    return currentDate;
};

const getCurrentMonth = () => {
    // Default to today
    const today = new Date();
    // But we need a particular format (and yes, adding 1900 is Y2K safe!)
    const currentMonth = `${1900 + today.getYear()}-${twoDigit(
        today.getMonth() + 1
    )}`;

    return currentMonth;
};

const getCurrentYear = () => {
    // Default to today
    const today = new Date();
    // But we need a particular format (and yes, adding 1900 is Y2K safe!)
    const currentYear = `${1900 + today.getYear()}`;

    return currentYear;
};

const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

// Check whether a list of date values are in dmy or mdy format
const checkDMYFormat = (wordcounts) => {
    for (var i = 0; i < wordcounts.length; i++) {
        const date = wordcounts[i].wordcountDate.split('/');
        if (date[1] > 12) {
            return false;
        }
    }
    return true;
};

// Pretty print a date - if it's this year we drop the year number.
// If it's yesterday or today we say so.
// Otherwise date as ordinal and month as word
// Call with 'short' to reduce month to 3 letters
const formatDate = (date, type = 'long') => {
    if (isNaN(Date.parse(date))) {
        return null;
    }
    const dateObj = new Date(date);
    const req = {};

    req.year = dateObj.getFullYear();
    req.month = dateObj.getMonth();
    req.day = dateObj.getDate();

    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth();
    const thisDay = new Date().getDate();

    const monthName =
        type === 'long'
            ? monthNames[req.month]
            : monthNames[req.month].substr(0, 3);

    // Most of the time it'll be this format
    let formattedDate = `${ordinal(req.day)} ${monthName}`;

    // If it's not this year, we need to add the year
    if (thisYear !== req.year) {
        formattedDate = `${ordinal(req.day)} ${monthName} ${req.year}`;
    }

    // if it's this year and month
    if (thisYear === req.year && thisMonth === req.month) {
        // If it's today, let's just say that
        if (thisDay === req.day) {
            formattedDate = 'today';
        }
        // Or yesterday
        if (thisDay - 1 === req.day) {
            formattedDate = 'yesterday';
        }
    }
    return formattedDate;
};

const roundNumber = (input) => {
    var words = [
        '',
        'thousand',
        'million',
        'billion',
        'trillion',
        'quadrillion',
    ];

    if (typeof input === 'number') {
        if (input < 1000) {
            return input;
        }
        // Log division is equivalent to working out the exponent
        var exp = Math.floor(Math.log(input) / Math.log(1000));
        // Return the rounded down version of what's raised to that exponent
        return (
            'over ' + Math.floor(input / Math.pow(1000, exp)) + ' ' + words[exp]
        );
        // If you want to round properly, including up:
        // return (input / Math.pow(1000, exp)).toFixed(0) + " " + words[exp];
    } else {
        return `${input} is not a number`;
    }
};

export {
    twoDigit,
    sortCounts,
    inFillCounts,
    splitDate,
    ordinal,
    dayNames,
    monthNames,
    getCurrentDate,
    getCurrentMonth,
    getCurrentYear,
    timeout,
    checkDMYFormat,
    formatDate,
    roundNumber,
};
