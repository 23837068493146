import { Card } from 'react-bootstrap';
import {
    FaTwitter,
    FaFacebook,
    FaReddit,
    FaLinkedin,
    FaEnvelope,
} from 'react-icons/fa';
import { useAppContext } from '../../libs/contextLib';
import './Share.css';

const Share = () => {
    const { justAdded } = useAppContext();

    const url = 'https://writerlog.app';
    const title = `I just logged ${justAdded} words using %23WriterLog (${url})`;
    const text = `I just logged ${justAdded} words using %23WriterLog - track your writing progress too!`;

    return (
        <Card className="share">
            <Card.Body>
                <Card.Title>You just added {justAdded} words!</Card.Title>
                <Card.Text>Why not share that with the world?</Card.Text>
            </Card.Body>
            <Card.Footer>
                <a
                    href={`https://twitter.com/share?text=${text}&url=${url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="share-btn twitter"
                >
                    <FaTwitter />
                    <div className="hidden">Twitter</div>
                </a>

                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`}
                    target="_blank"
                    rel="noreferrer"
                    className="share-btn facebook"
                >
                    <FaFacebook />
                    <div className="hidden">Facebook</div>
                </a>

                <a
                    href={`https://reddit.com/submit?url=${url}&title=${title}`}
                    target="_blank"
                    rel="noreferrer"
                    className="share-btn reddit"
                >
                    <FaReddit />
                    <div className="hidden">Reddit</div>
                </a>

                <a
                    href={`https://www.linkedin.com/shareArticle?url=${url}&title=${title}&summary={{ $pagesummary }}&source=${url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="share-btn linkedin"
                >
                    <FaLinkedin />
                    <div className="hidden">LinkedIn</div>
                </a>

                <a
                    href={`mailto:info@example.com?subject=${title}&amp;body=${url}%0D%0A%0D%0A${text}`}
                    target="_blank"
                    rel="noreferrer"
                    className="share-btn email"
                >
                    <FaEnvelope />
                    <div className="hidden">Email</div>
                </a>
            </Card.Footer>
        </Card>
    );
};

export default Share;
