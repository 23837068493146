import { dayNames, monthNames } from './utilLib';

const makeCalendar = (
    startDate: Date,
    endDate: Date,
    myWords: wordCount[],
    target: number
): calendar[] => {
    // Create a calendar object for a given period, with or without a user

    // Create an array of objects, ready to use, the exact size we need
    let dailyCounts: calendar[] = initializeDailyCounts(startDate, endDate);

    // @@@FIXME@@@ Shouldn't need this extra check
    // For every wordcount object we got back, go over it
    for (let index = 0; index < myWords.length; index++) {
        if (typeof myWords[index].wordcountDate === 'string') {
            myWords[index].wordcountDate = new Date(
                myWords[index].wordcountDate
            );
        }
        // Take the date the wordcount object has, and work out how far from the start it is
        var myDate = Math.floor(
            // Date we got minus date we started on, in ms - so divide by ms in a day
            (myWords[index].wordcountDate.getTime() - startDate.getTime()) /
                (1000 * 60 * 60 * 24)
        );
        // If it's under 0, it's in the past according to this calendar - so we won't use it.
        // If it's higher than the number of entries in our list then it's also outside our view
        if (myDate >= 0 && myDate < dailyCounts.length) {
            // now use that offset to set the correct day in the returned object
            dailyCounts[myDate].wordcount += myWords[index].words;

            // Default is that we didn't do it (set in initialize)
            // If we're over the target, we change it to say we passed
            if (dailyCounts[myDate].wordcount >= target) {
                dailyCounts[myDate].extras = 'pass';
            } else {
                // If we're under the target, but submitted a count for that day, we fail
                dailyCounts[myDate].extras = 'fail';
            }
        }
    }

    return dailyCounts;
};

// Set up an array to cover a given period of days, with zero counts to be populated later
const initializeDailyCounts = (startDate: Date, endDate: Date): calendar[] => {
    // How many days did we ask for (end-start gives milliseconds, so we turn it back into days)
    // round is because summer time changes shift us just under or just over the day boundary
    const daysRequested = Math.round(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    // Initialize an empty array to push to
    let dailyCounts: calendar[] = [];

    // For every day in the range we asked for
    for (let i = 0; i <= daysRequested; i++) {
        // Set up a date object for that day
        let thisDay = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + i,
            0,
            0,
            0
        );

        const today = new Date();
        let extraTags = '';

        // Is it in the future?
        if (thisDay.getTime() - today.getTime() > 0) {
            extraTags = 'notyet';
        } else if (
            thisDay.getTime() - today.getTime() >
            -(1000 * 60 * 60 * 24)
        ) {
            extraTags = 'today';
        } else {
            extraTags = 'notdone';
        }

        // And then push the data structure with no wordcount, ready to fill in later
        dailyCounts.push({
            // Full date in JS format
            date: thisDay,
            // Day number as a digit
            day: thisDay.getDate(),
            // Name of the day of the week
            dayName: dayNames[thisDay.getDay()],
            // Name of the month of the year
            monthName: monthNames[thisDay.getMonth()],
            // Year
            year: thisDay.getFullYear(),
            // How many words that day
            wordcount: 0,
            // Any tags that want to be put in the class name when rendered
            extras: extraTags,
        });
    }
    // Send that back for further use
    return dailyCounts;
};

const getPrevMonth = (year: number, month: number): number[] => {
    var prevYear = year;
    var prevMonth = month - 1;
    if (prevMonth < 0) {
        prevMonth = 11;
        prevYear = prevYear - 1;
    }
    return [prevYear, prevMonth];
};

const getNextMonth = (year: number, month: number): number[] => {
    var nextMonth;
    var nextYear;

    // We need to know today, to know this month
    const today = new Date();

    // If we're in the current calendar month
    if (year === today.getFullYear() && month === today.getMonth()) {
        nextYear = 0;
        nextMonth = 99;
    } else {
        nextMonth = month + 1;
        nextYear = year;
        if (nextMonth > 11) {
            nextMonth = 0;
            nextYear = nextYear + 1;
        }
    }
    return [nextYear, nextMonth];
};

const getStartDate = (year: number, month: number): Date => {
    return new Date(year, month, 1, 0, 0, 0);
};

const getDaysInMonth = (year: number, month: number): number => {
    // Day 0 of the next month is the same as the last day of this month
    return new Date(year, month + 1, 0).getDate();
};

const getEndDate = (year: number, month: number): Date => {
    // Day 0 of the next month is the same as the last day of this month
    return new Date(year, month, getDaysInMonth(year, month), 0, 0, 0);
};

export {
    makeCalendar,
    initializeDailyCounts,
    getPrevMonth,
    getNextMonth,
    getStartDate,
    getEndDate,
    getDaysInMonth,
};
