import { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { BsFillInfoCircleFill } from 'react-icons/bs';
// Allows links to work without refresh
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// Let's use a context, rather than passing everything back and forth
import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
import { fetchTotals } from './libs/totalLib';
import { fetchProfile } from './libs/profileLib';
import { fetchWordCountArray } from './libs/wordCountLib';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Banner from './components/Banner/Banner';
import MyRoutes from './Routes';
import './App.css';

const App = () => {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [hasData, setHasData] = useState(false);
    const [totals, setTotals] = useState({});
    const [justAdded, setJustAdded] = useState(0);
    const [profile, setProfile] = useState([]);
    const [wordCounts, setWordCounts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const onLoad = async () => {
            // See if we are logged in
            try {
                await Auth.currentSession();
                userHasAuthenticated(true);

                // Get the user ID
                let user;
                try {
                    user = await Auth.currentUserInfo();
                } catch (e) {
                    onError(e);
                }

                // Grab the totals from the DB
                // Grab the user's profile
                // Grab the user's wordcounts
                Promise.all([
                    fetchTotals(),
                    fetchProfile(user.id),
                    fetchWordCountArray(),
                ])
                    .then((results) => {
                        setTotals(results[0]);
                        setProfile(results[1]);
                        setWordCounts(results[2]);

                        setIsLoading(false);
                        setHasData(true);
                    })
                    .catch((e) => {
                        onError(e);
                    });
            } catch (e) {
                // If we are not logged in
                // or the error is different
                if (e !== 'No current user') {
                    onError(e);
                }
                // If we're just not logged in, then we're loaded
                setIsLoading(false);
            }

            setIsAuthenticating(false);
        };
        onLoad();
        // We don't want it to reload every time we change the data
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const handleLogout = async () => {
        await Auth.signOut();
        userHasAuthenticated(false);
        navigate('/login');
    };
    return (
        !isAuthenticating && (
            <div className="App">
                <Banner />
                <Navbar collapseOnSelect expand="md" className="mb-3">
                    <LinkContainer to="/">
                        <Navbar.Brand className="font-weight-bold">
                            Writer Log
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav activeKey={window.location.pathname}>
                            {isAuthenticated ? (
                                <>
                                    <LinkContainer to="/dashboard">
                                        <Nav.Link active={false}>
                                            Dashboard
                                        </Nav.Link>
                                    </LinkContainer>
                                    {wordCounts.length > 2 ? (
                                        <LinkContainer to="/graphs">
                                            <Nav.Link active={false}>
                                                Graphs
                                            </Nav.Link>
                                        </LinkContainer>
                                    ) : (
                                        <></>
                                    )}
                                    <LinkContainer to="/profile">
                                        <Nav.Link active={false}>
                                            Profile
                                        </Nav.Link>
                                    </LinkContainer>
                                    {/* v.1.2.5 - Remove UPLOAD due to issues with CSV
                                        https://github.com/mafintosh/csv-parser/issues/183
                                     <LinkContainer to="/upload">
                                        <Nav.Link active={false}>
                                            Upload
                                        </Nav.Link>
                                    </LinkContainer> */}
                                    <Nav.Link
                                        active={false}
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </Nav.Link>
                                </>
                            ) : (
                                <>
                                    <LinkContainer to="/signup">
                                        <Nav.Link active={false}>
                                            Signup
                                        </Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/login">
                                        <Nav.Link active={false}>
                                            Login
                                        </Nav.Link>
                                    </LinkContainer>
                                </>
                            )}
                            <LinkContainer to="/info">
                                <Nav.Link>
                                    <BsFillInfoCircleFill />
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <ErrorBoundary>
                    <AppContext.Provider
                        value={{
                            isAuthenticated,
                            userHasAuthenticated,
                            isLoading,
                            setIsLoading,
                            hasData,
                            setHasData,
                            justAdded,
                            setJustAdded,
                            totals,
                            setTotals,
                            profile,
                            setProfile,
                            wordCounts,
                            setWordCounts,
                        }}
                    >
                        <Container>
                            <MyRoutes />
                        </Container>
                    </AppContext.Provider>
                </ErrorBoundary>
            </div>
        )
    );
};

export default App;
