import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { dayNames, formatDate } from '../../libs/utilLib';
import { makeCalendar } from '../../libs/calendarLib';
import { useAppContext } from '../../libs/contextLib';
import './YearCalendar.css';

const YearCalendar = (): JSX.Element => {
    const { wordCounts } = useAppContext();
    const { profile } = useAppContext();
    const { isLoading } = useAppContext();

    const today = new Date();

    // Take today and a year ago, and ask for a calendar object
    const yearCalendar = makeCalendar(
        new Date(
            today.getFullYear() - 1,
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
        ),
        new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
        ),
        wordCounts,
        profile.target
    );
    // Create an empty array to populate
    let yearCal: JSX.Element[] = [];

    // Push the first entry down to account for the day of the week
    // Take first entry's name of the day and look it up in the list of days
    const offset = dayNames.findIndex(
        (element) => element === yearCalendar[0].dayName
    );

    // Put in that many 'dummy' entries that won't show up
    for (let i = 0; i < offset; i++) {
        yearCal.push(<div key={'skip' + i} className="skip"></div>);
    }

    if (isLoading) {
        for (let i = 0; i < yearCalendar.length; i++) {
            yearCal.push(<div className="square" key={i}></div>);
        }
    } else {
        // Now iterate over every day and push those entries onto the list
        for (let i = 0; i < yearCalendar.length; i++) {
            // Create a tool tip - date and words when you hover
            const dateTip: string = `${formatDate(
                yearCalendar[i].date,
                'short'
            )} - ${yearCalendar[i].wordcount.toLocaleString()} words`;

            // Add a new entry to the array, in JSX format ready to just include
            yearCal.push(
                <OverlayTrigger
                    key={i}
                    placement="top"
                    overlay={<Tooltip id={dateTip}>{dateTip}</Tooltip>}
                >
                    {({ ref, ...triggerHandler }) => (
                        <div
                            ref={ref}
                            {...triggerHandler}
                            className={'square ' + yearCalendar[i].extras}
                        ></div>
                    )}
                </OverlayTrigger>
            );
        }
    }
    return (
        <Card className="calendar yearCalendar mt-3">
            <Card.Header>Your year in writing</Card.Header>
            <Card.Body>
                <div className="calwrap">
                    <div
                        id="yearCal"
                        className={`yearCal ${isLoading ? 'loading' : ''}`}
                    >
                        {yearCal}
                    </div>
                    <div className="yearCal calendarMeta">
                        <div className="key">Key:</div>
                        <div className="square notdone"></div>
                        <div className="caption">No words entered</div>
                        <div className="square fail"></div>
                        <div className="caption">Target not reached</div>
                        <div className="square pass"></div>
                        <div className="caption">Target passed</div>
                        <div className="square today"></div>
                        <div className="caption">Today</div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
export default YearCalendar;
