const dev = {
    s3: {
        REGION: 'eu-west-2',
        BUCKET: 'dev-writerlog-infra-s3-profileimages9660da25-1rn5xptybo1t7',
    },
    apiGateway: {
        REGION: 'eu-west-2',
        URL: 'https://dev-api.writerlog.app',
    },
    cognito: {
        REGION: 'eu-west-2',
        USER_POOL_ID: 'eu-west-2_RmpyprdNw',
        APP_CLIENT_ID: '19u3oojop29qrqdtmrre30j8oo',
        IDENTITY_POOL_ID: 'eu-west-2:326da931-bd76-439a-a4de-bd765ce14099',
    },
};

const prod = {
    s3: {
        REGION: 'eu-west-2',
        BUCKET: 'prod-writerlog-infra-s3-profileimages9660da25-pou7h7byqxqc',
    },
    apiGateway: {
        REGION: 'eu-west-2',
        URL: 'https://api.writerlog.app',
    },
    cognito: {
        REGION: 'eu-west-2',
        USER_POOL_ID: 'eu-west-2_gUKLZvzMJ',
        APP_CLIENT_ID: '5hj9347f0dtcjsbc7vpvknmanp',
        IDENTITY_POOL_ID: 'eu-west-2:cb42c89d-b2b6-4565-95b3-3bb520ef1cc9',
    },
};

const config = {
    // Common Settings
    MAX_ATTACHMENT_SIZE: 5000000,
    ...(process.env.REACT_APP_STAGE === 'prod'
        ? prod
        : process.env.REACT_APP_STAGE === 'beta'
        ? prod
        : dev),
};
export default config;
