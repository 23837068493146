import { API } from 'aws-amplify';

const fetchTotals = () => {
    const totals: Promise<totals> = API.get('writerlog', '/api/totals', {});
    return totals;
};
const updateTotals = (update: totals) => {
    const totals: Promise<totals> = API.put('writerlog', '/api/totals', {
        body: update,
    });
    return totals;
};

export { fetchTotals, updateTotals };
