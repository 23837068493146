import { useEffect } from 'react';
import { useAppContext } from '../libs/contextLib';

import BarGraph from '../components/Graphs/BarGraph';
import LineGraph from '../components/Graphs/LineGraph';
import Lander from '../components/Lander/Lander';
import SupportButton from '../components/SupportButton/SupportButton';

import './Graphs.css';

const Graphs = () => {
    const { isAuthenticated } = useAppContext();
    useEffect(() => {
        const onLoad = () => {
            if (!isAuthenticated) {
                return;
            }
        };

        onLoad();
    }, [isAuthenticated]);

    const renderData = () => {
        return (
            <>
                <BarGraph />
                <LineGraph className="mt-3" />
                <SupportButton />
            </>
        );
    };
    return (
        <div className="Graphs">
            {isAuthenticated ? renderData() : <Lander />}
        </div>
    );
};

export default Graphs;
