import { Tabs, Tab, ListGroup, Row, Col } from 'react-bootstrap';
import SupportButton from '../components/SupportButton/SupportButton';

import './Info.css';

const Privacy = () => {
    return (
        <div className="privacy mt-5 mb-5">
            <Row className="justify-content-md-center">
                <Col sm={12} md={8}>
                    If there's something you want to know that isn't covered
                    below, why not{' '}
                    <a href="mailto:bugs@writerlog.app">let me know?</a>
                    <ListGroup className="mt-3">
                        <ListGroup.Item>
                            <h3>We will never spam you</h3>
                            <p className="small">
                                You're trusting us with your email address, and
                                we will never share it with anyone else. It will
                                be used to log you in and for occasional product
                                updates. You can unsubscribe from these at any
                                time, and continue to use the service.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>We use Amazon Web Services</h3>
                            <p className="small">
                                In common with many, many major websites we use
                                AWS to run this service.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Cookies</h3>
                            <p className="small">
                                We set a cookie to keep you logged in, and allow
                                you frictionless use of the service. We do not
                                track you.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Your data is safe</h3>
                            <p className="small">
                                We ask for the bare minimum that we need to
                                operate this service (we don't need your
                                birthday, for example).
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
};

const Updates = () => {
    return (
        <div className="updates mt-5 mb-5">
            <Row className="justify-content-md-center">
                <Col sm={12} md={8}>
                    <ListGroup className="highlight">
                        <ListGroup.Item>
                            <h3>29th December 2022 (v1.2.6)</h3>
                            <p>Upgrade base software</p>
                            <p className="small">
                                React v18, along with varied dependencies. This
                                required some retooling and troubleshooting.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>28th December 2022 (v1.2.5)</h3>
                            <p>Bug fixes</p>
                            <p className="small">
                                Graphs were missing data from daylight savings
                                periods.
                            </p>
                            <p>Disable CSV Upload</p>
                            <p className="small">
                                Due to a software change, we can currently not
                                support uploading of CSV files.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>28th December 2022 (v1.2.4)</h3>
                            <p>Bug fixes</p>
                            <p className="small">
                                Problem with fonts in Firefox resolved.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>1st March 2021 (v1.2.3)</h3>
                            <p>Bug fixes</p>
                            <p className="small">
                                March had 30 days. (For those curious, it
                                rounded down because losing an hour to Daylight
                                Savings made it 30.95 days)
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>26th February 2021 (v1.2.2)</h3>
                            <p>Bug fixes</p>
                            <p className="small">
                                On signing up it was possible for the profile
                                not to be created correctly. This release fixed
                                that.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>19th February 2021 (v1.2.1)</h3>
                            <p>Bug fixes</p>
                            <p className="small">
                                Graphs weren't working so well if you didn't
                                have many words logged. This should be better
                                now.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>15th February 2021 (v1.2.0)</h3>
                            <p>Monthly Calendar</p>
                            <p className="small">
                                You can click on each day to add or change the
                                wordcount, navigate back and forth through the
                                months and click on the month name to go back to
                                this today.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>13th February 2021 (v1.1.3)</h3>
                            <p>Additional Speed Ups</p>
                            <p className="small">
                                Accellerated the loading. Also improved
                                readability and accessibility.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>12th February 2021 (v1.1.1)</h3>
                            <p>Bug fix</p>
                            <p className="small">
                                Squashed a bug that meant you sometimes had to
                                reload after logging in.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>11th February 2021 (v1.1.0)</h3>
                            <p>Graphs!</p>
                            <p className="small">
                                You can see your wordcount day by day, and also
                                track your total words as they grow over time.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>6th February 2021 (v1.0.0)</h3>
                            <p>Upload CSV File</p>
                            <p className="small">
                                You can upload a simple CSV (Comma Separated
                                Variable) file containing past word counts.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>5th February 2021 (v0.3.0)</h3>
                            <p>Added a share panel</p>
                            <p className="small">
                                Now whenever you add some new words, it pops up
                                to ask if you want to share your amazing
                                progress.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>4th February 2021 (v0.2.2)</h3>
                            <p>Bug fixes and speed ups</p>
                            <p className="small">
                                Squashed a couple of small issues and made the
                                whole thing a little snappier.
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h3>31st January 2021 (v0.2.1)</h3>
                            <p>Comparisons added</p>
                            <p className="small">
                                Write enough words and you'll be informed which
                                famous novels your wordcount compares to.
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h3>30th January 2021 (v0.2.0)</h3>
                            <p>First release</p>
                            <p className="small">
                                Basic profile capability, wordcount logging and
                                simple statistics.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
};

const Future = () => {
    return (
        <div className="future mt-5 mb-5">
            <Row className="justify-content-md-center">
                <Col sm={12} md={8}>
                    Please note that these are only plans, and while they are in
                    the approximate order in which I intend to develop them,
                    some might appear before others. If there's something
                    missing from this list, or you think there's a feature you
                    want more than something else, why not{' '}
                    <a href="mailto:bugs@writerlog.app">let me know?</a>
                    <ListGroup className="mt-3">
                        <ListGroup.Item className="done">
                            <h3>Upload your past wordcounts</h3>
                            <p className="small">
                                Bring in your existing wordcount lists without
                                entering them all one by one.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item className="done">
                            <h3>Sharing</h3>
                            <p className="small">
                                Share your progress with social media.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Additional Statistics</h3>
                            <p className="small">
                                Your average word counts per week, month, year.
                            </p>
                            <p className="small">
                                Comparable word counts based on all users.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>View other users</h3>
                            <p className="small">
                                See how other users are getting on, and connect
                                with them.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Leaderboard</h3>
                            <p className="small">
                                See how you measure up against the other authors
                                on the site.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Track by type</h3>
                            <p className="small">
                                Log whether you were writing fiction,
                                non-fiction or editing.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item className="done">
                            <h3>Graphs</h3>
                            <p className="small">Graphs of your wordcounts.</p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Projects</h3>
                            <p className="small">
                                Log against a particular work in progress, with
                                targets and deadlines.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Badges</h3>
                            <p className="small">
                                Earn badges by logging and writing more.
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Embeds</h3>
                            <p className="small">
                                Embed your graphs, wordcounts and badges on your
                                own website.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
};

const Info = () => {
    return (
        <div className="info">
            <Tabs defaultActiveKey="updates" id="tablist">
                <Tab eventKey="privacy" title="Privacy">
                    <Privacy />
                </Tab>
                <Tab eventKey="updates" title="Updates">
                    <Updates />
                </Tab>
                <Tab eventKey="future" title="Future Plans">
                    <Future />
                </Tab>
            </Tabs>
            <SupportButton />
        </div>
    );
};
export default Info;
