import { Link } from 'react-router-dom';
import './Lander.css';

const Lander = (): JSX.Element => {
    return (
        <div className="lander">
            You need to be logged in.
            <div className="pt-3">
                <Link to="/login" className="btn btn-info btn-lg mr-3">
                    Login
                </Link>
                <Link to="/signup" className="btn btn-success btn-lg">
                    Signup
                </Link>
            </div>
        </div>
    );
};
export default Lander;
