import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
    VictoryChart,
    VictoryBar,
    VictoryAxis,
    VictoryTheme,
    VictoryLine,
    VictoryLabel,
    VictoryTooltip,
    Flyout,
} from 'victory';
import { useAppContext } from '../../libs/contextLib';
import { formatDate, inFillCounts } from '../../libs/utilLib';

import './BarGraph.css';

const BarGraph = ({ thumbnail = false, className = '' }) => {
    const { wordCounts } = useAppContext();
    const { profile } = useAppContext();
    const { hasData } = useAppContext();

    // Milliseconds per day
    const msPerDay = 24 * 60 * 60 * 1000;
    const todayMS = Date.now();

    // Defaults for while we're loading
    var startDate = todayMS - msPerDay;
    var endDate = todayMS + msPerDay;
    var sortedCounts = [];
    var bar = <></>;
    var targetLine = <></>;
    var ticks = 1;

    if (!hasData || wordCounts.length === 0) {
        return <></>;
    } else {
        if (wordCounts.length < 3) {
            return (
                <Card className="m-1">
                    <Card.Header>Word Counts</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <small>
                                Add more data to get a graph of your progress
                            </small>
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        } else {
            sortedCounts = inFillCounts(wordCounts);

            // Let's not have too many marks on the graph
            ticks =
                wordCounts.length - 1 > 0 && wordCounts.length < 20
                    ? wordCounts.length - 1
                    : 20;

            // Offset the X-axis to the first data, not the start of time
            startDate = sortedCounts[0].wordcountDate.getTime();

            bar = (
                <VictoryBar
                    data={sortedCounts}
                    x={(d) => Date.parse(d.wordcountDate)}
                    y="words"
                    alignment="start"
                    labels={({ datum }) =>
                        `${formatDate(
                            datum.wordcountDate,
                            'short'
                        )}: ${datum.words.toLocaleString()}`
                    }
                    labelComponent={
                        thumbnail ? (
                            <></>
                        ) : (
                            <VictoryTooltip
                                style={{
                                    fontSize: 4,
                                    fill: ({ datum }) =>
                                        datum.words >= 200 ? 'green' : 'red',
                                }}
                                flyoutPadding={1}
                                cornerRadius={1}
                                flyoutStyle={{
                                    strokeWidth: 0,
                                }}
                                pointerLength={0}
                                flyoutComponent={<Flyout />}
                            />
                        )
                    }
                    style={{
                        data: {
                            fill: ({ datum }) =>
                                datum.words >= profile.target ? 'green' : 'red',
                            stroke: ({ datum }) =>
                                datum.words >= 200 ? 'darkgreen' : 'darkred',
                            strokeWidth: 1,
                        },
                    }}
                />
            );

            targetLine = (
                <VictoryLine
                    y={() => 200}
                    style={{
                        data: {
                            stroke: 'blue',
                            strokeWidth: 1,
                        },
                    }}
                />
            );

            var xAxis = (
                <VictoryAxis
                    tickCount={ticks}
                    tickLabelComponent={<></>}
                    gridComponent={<></>}
                />
            );
            var yAxis = (
                <VictoryAxis
                    dependentAxis
                    tickLabelComponent={<></>}
                    gridComponent={<></>}
                />
            );

            if (!thumbnail) {
                xAxis = (
                    <VictoryAxis
                        fixLabelOverlap={true}
                        tickFormat={(x) => `${formatDate(x, 'short')}`}
                        tickCount={ticks}
                        gridComponent={<></>}
                        tickLabelComponent={
                            <VictoryLabel
                                angle={60}
                                style={{ fontSize: 4 }}
                                dx={-6}
                                dy={-5}
                                textAnchor="start"
                            />
                        }
                    />
                );
                yAxis = (
                    <VictoryAxis
                        dependentAxis
                        gridComponent={<></>}
                        // tickFormat={(x) => `${x / 1000}k`}
                        tickLabelComponent={
                            <VictoryLabel
                                style={{ fontSize: 4 }}
                                textAnchor="start"
                            />
                        }
                    />
                );
            }
            var graphCard = (
                <Card className={`m-1 barGraph graph ${className}`}>
                    <Card.Header>Word Counts</Card.Header>
                    <Card.Body>
                        <VictoryChart
                            height={200}
                            scale={{ x: 'time', y: 'linear' }}
                            minDomain={{ x: startDate }}
                            maxDomain={{ x: endDate }}
                            theme={VictoryTheme.material}
                            padding={
                                thumbnail
                                    ? 0
                                    : {
                                          bottom: 30,
                                          left: 20,
                                          top: 10,
                                          right: 10,
                                      }
                            }
                        >
                            {xAxis}
                            {yAxis}
                            {bar}
                            {targetLine}
                        </VictoryChart>
                    </Card.Body>
                    {!thumbnail && (
                        <Card.Footer>
                            <div className="barGraphMeta graphMeta">
                                <div className="key">Key:</div>
                                <div className="square fail"></div>
                                <div className="caption">
                                    Target not reached
                                </div>
                                <div className="square pass"></div>
                                <div className="caption">Target passed</div>
                                <div className="square target"></div>
                                <div className="caption">Target wordcount</div>
                            </div>
                        </Card.Footer>
                    )}
                </Card>
            );

            if (thumbnail) {
                return (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="line-graph-tooltip">
                                Click to expand
                            </Tooltip>
                        }
                    >
                        {({ ref, ...triggerHandler }) => (
                            <LinkContainer to="/graphs">
                                {graphCard}
                            </LinkContainer>
                        )}
                    </OverlayTrigger>
                );
            } else {
                return <>{graphCard}</>;
            }
        }
    }
};

export default BarGraph;
